<template>
  <div class="sps">
    <a-icon class="close-tag" @click="handleClose" type="close-circle" />

    <div class="title"> {{ $t('budhi.spTitle') }} </div>

    <a-row :gutter="16" class="list">
      <a-col :span="8" v-for="(item,index) in spList" :key="index">
          <div class="box item" @click="handleDonate(item, index+1)">
              <div class="img">
                <img src="@/assets/svgs/sp.svg" class="icon" />
              </div>
              <span>{{ item[1] }}</span>

              <div class="price">{{ item[2] }}U</div>

          </div>
      </a-col>

    </a-row>
  </div>
</template>

<script>
// spiritual practice
import Shelldao from "@/web3/tron/shelldao";
const DONATE_TYPE_SP = 2; //菩提園捐贈類型為進場修行
const DONATE_TYPE_SP_UP = 4; ////菩提園捐贈類型為升级修行
export default {
  name: 'SpiritualPractice',
  props: {
    isUpgrade: {
      type: Boolean,
      default: false
    },
    upgradeBaseAmount: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      loading: false,
      spList: [],
      userUsdtBalance: 0,
      approvedUsdtAmount: 0,
      tips:'',
    }
  },
  mounted () {
    Shelldao.init((r)=>{
      r.gUsdtToken.balanceOf(r.gWalletAddress).call().then((res)=>{
        this.userUsdtBalance = res.toNumber() / 1000000;
        console.log('usdt balance:', this.userUsdtBalance);
      });
      Shelldao.getBudhiApprovedAmount((res)=>{
        let _approvedAmount=0;
        if(res.remaining===undefined){
            _approvedAmount = res.toNumber();
        }else{
            _approvedAmount = res.remaining.toNumber();
        }
        this.approvedUsdtAmount = _approvedAmount/1000000;
        console.log('approved usdt:', this.approvedUsdtAmount);
      });
    });
    this.getSps();
  },
  destroyed () {
  },
  methods: {
    handleClose(){
      this.$emit('close');
    },
    getSps() {
      let _this = this
      this.$http.call(this.$http.api.sps).then(res=>{
          console.log(res.data);
          if(res.data.code == '200'){
            _this.spList = res.data.data;
          }else{
            _this.$message.info(res.data.data)
          }
      },res=>{
          // console.log('something goes wrong...');
          console.log(res.response);
          if(res.response.status == '401'){
              this.$store.commit('setToken','');
              this.$router.push({name:'login'});
          }
      });
    },
    handleDonate(sp, sp_id){
      let _this = this;
      let _donate_type = DONATE_TYPE_SP;
      let _amount = sp[2]*1000000;
      if(this.isUpgrade){
        if(this.upgradeBaseAmount >= sp[2]){
          this.$message.info('无需升级');return;
        }
        _amount = (sp[2] - this.upgradeBaseAmount)*1000000;
        _donate_type = DONATE_TYPE_SP_UP;
      }
      if(this.approvedUsdtAmount <= _amount){
        this.loading = true;
        Shelldao.budhiApproveToken(_amount*10, (res)=>{
          console.log(res)
          Shelldao.budhiDonate(_amount, _donate_type, sp_id, ()=>{
            _this.$message.success('success');
            _this.loading = false;
          });
        });
      }else{
        Shelldao.budhiDonate(_amount, _donate_type, sp_id, ()=>{
          _this.$message.success('success');
          _this.loading = false;
        });
      }
      
    }
  }
}
</script>

<style lang="less" scoped>
  .sps{
    width: 100%;
    padding: 15PX;
    border-radius: 15PX;
    color:#333;
    position: relative;
    background: #dadada;
    .close-tag {
      position: absolute;
      top: 15PX; right: 15PX;
    }
    .title {
      margin-bottom: 15PX;
    }
    .list {
      .item {
        padding: 15PX;
        margin-bottom: 15PX;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .img {
          .icon {
            height: 38PX;
          }
        }
        .price {
          padding-top: 7PX;
        }
      }
    }
  }
</style>
